import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../services/helpers/app.service";
import { EventsService } from "../../services/helpers/events.service";
import { HelperService } from "../../services/helpers/helper.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { FormatNumbersService } from "../../services/helpers/format-numbers.service";

@Component({
  selector: "ngx-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
  lang: string;
  id: any;
  invoices: any = [];
  end_date;
  payment_method: string = null;
  partialChecked: any;
  partialForm: FormGroup;
  msgErr: boolean = true;
  invoice_type_check: boolean;
  total_invoice_amount: any = "0.00";
  invoiceID: any;
  fees_amount: any = [];
  payTotal;
  showInvoices: boolean = false;
  partialCheckedShow: boolean = false;
  valueCheck;
  updateInvoiceID: any;
  card_active: boolean = false;
  activeArray: any[] = [];
  amountBeforeDiscount: any = 0;
  fawry: any;
  invoicesCount = 0;
  getUrlData: any;
  install_value: any;
  payment_integration: any;
  payer_id: any;
  allInvoices: any[];
  //new
  invoicesCheckedData: any = [];
  isSelectedAll: boolean;

  total_amount: any;
  total_after_discount: any;
  total_discount: any = 0;
  subtotal: any = 0;
  partial_amount: any;
  total_fees: any = 0;
  total_amount_payed: any;
  // remaining_amount: any;
  total: any = "0.00";

  policyChecked: any;

  invoiceForm: FormGroup;
  finalTotal: any = 0;
  validToPay: boolean;
  constructor(
    private avroute: ActivatedRoute,
    private helper: HelperService,
    private appService: AppService,
    private eventsService: EventsService,
    public tranlateService: TranslateService,
    private router: Router,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    public formateService: FormatNumbersService
  ) {}

  ngOnInit(): void {
    this.partialForm = this.formBuilder.group({
      partial_amount: ["", [Validators.pattern("^[0-9]+(.[0-9]+)?$")]],
    });
    this.id = this.avroute.snapshot.params["id"];
    this.getPaymentIntegration();
    this.getURL();
    this.eventsService.changeLangObser.subscribe((res) => {
      this.lang = res;
    });
    this.invoiceForm = this.formBuilder.group({
      invoices: this.formBuilder.array([this.createInvoiceArray()]),
    });
  }

  // add invoice data
  createInvoiceArray() {
    return this.formBuilder.group({
      invoice_id: [""],
      amount: [""],
    });
  }

  get invoice(): FormArray {
    return this.invoiceForm.get("invoices") as FormArray;
  }
  // ################### Start partial checkbox ==> single invoice ###################
  // partial amount not required now. if not, please remove this comment.
  onChangeShow(event) {
    this.partialChecked = event.target.checked;
    this.invoices.forEach((invoice) => {
      this.total_invoice_amount = invoice.amount_remaining;
      if (!event.target.checked) {
        this.partial_amount = null;
        this.msgErr = true;
        this.total = invoice.amount_remaining;
        this.invoice.value[0].invoice_id = invoice.invoice_short_uuid;
        this.invoice.value[0].amount = invoice.amount_remaining;
        this.getfees(this.invoice.value);
      }
      this.total_discount = this.moneyFormatter(invoice.discount);
      this.total_amount = this.moneyFormatter(invoice.amount_remaining);
      this.total = this.moneyFormatter(this.total_amount);
      this.CaluclateSubtotal(this.total, 0);
    });
  }
  // ################### End partial checkbox ==> single invoice ###################
  selectAll(event) {
    let data;
    this.resetAllData();
    this.isSelectedAll = event.target.checked;
    if (event.target.checked) {
      this.invoices.forEach((invoice) => {
        invoice.status.active = event.target.checked;
        invoice.status.checked = event.target.checked;
      });
      this.calcTotalAmountOfAllInvoices(this.invoices);
      this.invoices.forEach((val, i) => {
        data = {
          invoice_id: val.invoice_short_uuid,
          amount: val.amount_remaining,
        };
        this.resetNullValue();
        if (
          this.invoice.value.filter((x) => x.invoice_id === data.invoice_id)
            .length === 0
        ) {
          this.invoice.value.push(data);
        }
      });
      this.getfees(this.invoice.value);
      this.invoicesCheckedData = this.invoices;
    } else if (!event.target.checked) {
      this.invoices.forEach((invoice, index) => {
        invoice.status.active = event.target.checked;
        invoice.status.checked = event.target.checked;
        if (index == 0) {
          invoice.status.active = true;
        }
      });
      this.resetAllData();
    }
  }

  onChangePartial(event, value, invoiceData) {
    this.total_invoice_amount = 0;
    this.valueCheck = value;
    this.partialCheckedShow = event.target.checked;
    let newValue = 0;
    this.invoicesCheckedData.forEach((invoice) => {
      if (event.target.checked) {
        this.total_invoice_amount += Number(invoice?.amount_remaining);
        this.updateInvoiceID = invoice.invoice_short_uuid;
      } else {
        this.partialForm.get("partial_amount").setValue(null);
        newValue += Number(invoice?.amount_remaining);
        this.total = newValue;
        this.total_amount = newValue;
        this.CaluclateSubtotal(this.total_amount, 0);
        this.invoice.value.forEach((e) => {
          if (e.invoice_id == invoiceData.invoice_log[0].invoice_short_uuid) {
            e.amount = invoiceData?.amount_remaining;
          }
        });
        this.getfees(this.invoice.value);
      }
    });
  }

  lastPartialChange(event, invoiceData) {
    let partial_amount = this.partialForm.get("partial_amount").value;
    let newtotal_amount = 0;
    let newtotal_discount = 0;
    this.invoicesCheckedData.forEach((invoice) => {
      newtotal_amount += Number(invoice.amount_remaining);
      newtotal_discount += Number(invoice.discount);
    });
    if (partial_amount) {
      newtotal_amount -=
        this.invoicesCheckedData[this.invoicesCheckedData.length - 1]
          .amount_remaining;
      newtotal_amount += partial_amount;
      console.log(invoiceData);

      this.invoice.value.forEach((e) => {
        e.invoice_id == invoiceData.invoice_short_uuid
          ? (e.amount = this.partial_amount)
          : e.amount;
      });
    }
    this.total_amount = this.moneyFormatter(newtotal_amount);
    this.total_discount = this.moneyFormatter(newtotal_discount);
    this.total = this.moneyFormatter(newtotal_amount);
    this.amountBeforeDiscount = this.moneyFormatter(
      Number(this.total_discount) + Number(this.total)
    );
    this.CaluclateSubtotal(newtotal_amount, 0);
    this.getfees(this.invoice.value);
  }
  partialChange(event, invoice) {
    this.invoices.forEach((invoice) => {
      if (this.partialForm.get("partial_amount").value >= invoice.amount) {
        this.msgErr = false;
      } else {
        this.msgErr = true;
      }
    });
    this.updateInvoiceID = invoice.invoice_short_uuid;
    this.getTotalAmount();
  }
  getfees(invoices) {
    if (this.total > 0 && this.invoice.value.length > 0) {
      this.appService
        .POST(`receipt_getfees`, {
          invoices,
        })
        .subscribe((res) => {
          this.total_fees = res.commission + res.fixed_fees + res.round_up;
          this.finalTotal = res.total_amount;
        });
    } else {
      this.total_fees = 0;
      this.finalTotal = 0;
    }
  }
  // ###################Start Calculate Amount For Single Invoice ##################
  getTotalAmount() {
    let partial_amount = this.partialForm.get("partial_amount").value;
    this.invoices.forEach((invoice) => {
      this.invoiceID = invoice.id;
      this.total_invoice_amount = invoice.amount_remaining;
      if (partial_amount) {
        this.total_after_discount = this.moneyFormatter(partial_amount);
        this.CaluclateSubtotal(this.total_after_discount, 0);
      } else {
        this.total_after_discount = this.moneyFormatter(
          invoice.amount_remaining
        );
        this.CaluclateSubtotal(this.total_after_discount, 0);
      }
      this.total_amount = this.moneyFormatter(
        Number(invoice?.amount_remaining) + Number(invoice.discount)
      );
      this.total_discount = this.moneyFormatter(invoice.discount);
      this.total = this.moneyFormatter(this.total_after_discount);
      this.invoice.value[0].invoice_id = this.id;
      this.invoice.value[0].amount = this.total;
    });
    this.getfees(this.invoice.value);
  }
  // ################### End Calculate Amount For Single Invoice ##################

  // ################### Start Get Single Invoice ##################
  getURL() {
    let status = {};
    this.helper.showSpinner();
    this.appService.GET(`invoices/getUrlData/${this.id}`).subscribe(
      (res: any) => {
        this.getUrlData = res;
        this.validToPay = res?.payable;
        if (!this.validToPay) this.showMessageForDelayedInvoices();
        this.invoices = this.getInvoices(res);
        this.end_date = res?.invoice?.date_to;
        this.payer_id = res.invoice.payer.id;
        this.getInvoicesNumber(this.payer_id);
        this.helper.hideSpinner();
        // this.getTotalAmount();
        for (let index = 0; index < this.invoices.length; index++) {
          status = {
            index: index,
            active: false,
            checked: false,
          };
          if (index === 0) {
            status["active"] = true;
          }
          this.invoices[index]["status"] = status;
        }
      },
      (err) => {
        this.helper.hideSpinner();
        this.helper.showToastDanger("error", err.error.message);
      }
    );
  }
  // ################### End Get Single Invoice ##################
  showMessageForDelayedInvoices() {
    Swal.fire({
      title: this.tranlateService.instant(
        "You are late, please contact the company president to solve the problem"
      ),
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.tranlateService.instant("ok"),
    }).then(() => {});
  }
  // ################### Start Get Payment Integration ##################
  getPaymentIntegration() {
    this.appService.GET(`payment/method`).subscribe(
      (res: any) => {
        if (res.Payment_Gateway === "NBE") {
          this.payment_integration = "nbeCheckout";
        } else if (res.Payment_Gateway === "CAE") {
          this.payment_integration = "caeCheckout";
        } else {
          this.payment_integration = "nbeCheckout";
        }
      },
      (err) => {
        this.helper.showToastDanger("error", err.error.message);
      }
    );
  }
  // ################### End Get Payment Integration ##################
  moneyFormatter(num: any) {
    return parseFloat(num).toFixed(2);
  }
  getCType(num) {
    if (num === "1") {
      return "Rent";
    } else if (num === "2") {
      return "Maintenance";
    } else {
      return "Installment";
    }
  }
  dateFormatter(s: string) {
    let date = s.split("-");
    return date.join("/");
  }

  // ################### Start Format Invoice Data ##################
  getInvoices(response) {
    let results: any = [];
    if (typeof response === "object") {
      // i wanna sort invoices: rent invoices first then single invoices //
      // bcz i wanna user can choose contract invoices by order and also user can choose single invoices according to what he wants //
      // this make single invoices active and able to checked //
      response.invoices.forEach((res) => {
        if (res.contract) {
          results.push(this.formatInvoices(res));
        }
      });
      response.invoices.forEach((res) => {
        if (!res.contract) {
          results.push(this.formatInvoices(res));
        }
      });
    }
    return results;
  }

  formatInvoices(res) {
    let invoice;
    invoice = {
      unit_name: res?.contract?.unit?.unit_no || res?.unit?.unit_no,
      payer_name:res?.payer?.name,
      owner_name:res?.contract?.unit?.owner[0]?.name || res?.unit?.owner[0]?.name,
      contract_type:res?.contract?.service_type?.name      ,
      location: res?.contract?.unit?.address?.name || res?.unit?.address?.name,
      // contract_type: this.getCType(res?.contract?.contract_type_id),
      amount: this.moneyFormatter(Number(res?.amount_total)),
      amount_remaining: this.moneyFormatter(Number(res?.amount_remaining)),
      discount: this.moneyFormatter(Number(res?.discount)),
      owner: res?.contract?.unit?.owner[0]?.name,
      start_date: this.dateFormatter(res?.date_from),
      end_date: this.dateFormatter(res?.date_to),
      isChecked: false,
      status: res?.status,
      contract_name: res?.contract?.contract_name,
      invoice_short_uuid: res?.invoice_log[0]?.invoice_short_uuid,
      invoice_type: this.formatInvoiceType(res, "type"),
      invoice_type_key: this.formatInvoiceType(res, "key"),
      description: res?.description,
    };
    return invoice;
  }
  formatInvoiceType(res, need: string) {
    if (need == "type") {
      if (res.contract) {
        return this.lang == "en"
          ? res?.contract?.service_type?.name_en
          : res?.contract?.service_type?.name_ar;
      } else {
        return this.lang == "en"
          ? res?.invoice_type?.name_en
          : res?.invoice_type?.name_ar;
      }
    } else if (need == "key") {
      if (res.contract) {
        return res?.contract?.service_type?.name_en;
      } else {
        return res?.invoice_type?.name_en;
      }
    }
  }
  // ################### End Format Invoice Data ##################

  selectInvoiceCard(event, index, invoice) {
    let data;
    this.invoice_type_check = event.target.checked;
    if (event.target.checked) {
      if (index + 1 == this.invoices.length) {
        this.invoices[index]["status"].active = this.invoice_type_check;
        this.invoices[index]["status"].checked = this.invoice_type_check;
      } else {
        this.invoices[index + 1]["status"].active = this.invoice_type_check;
        this.invoices[index]["status"].checked = this.invoice_type_check;
      }
      this.invoicesCheckedData.push(invoice);
      this.invoicesCheckedData.forEach((val, i) => {
        data = {
          invoice_id: val.invoice_short_uuid,
          amount: val.amount_remaining,
        };
        this.resetNullValue();
        if (
          this.invoice.value.filter((x) => x.invoice_id === data.invoice_id)
            .length === 0
        ) {
          this.invoice.value.push(data);
        }
      });
    } else {
      if (index + 1 == this.invoices.length) {
        this.invoices[index]["status"].active = !this.invoice_type_check;
        this.invoices[index]["status"].checked = this.invoice_type_check;
      } else {
        for (let i = index; i < this.invoices.length; i++) {
          this.invoices[i]["status"].active = this.invoice_type_check;
          this.invoices[i]["status"].checked = this.invoice_type_check;
        }
        this.invoices[index]["status"].active = true;
        this.invoices[index]["status"].checked = this.invoice_type_check;
      }
      if (!this.isSelectedAll) {
        this.invoicesCheckedData.splice(
          this.invoicesCheckedData.indexOf(invoice)
        );
      } else {
        this.isSelectedAll = false;
        this.invoicesCheckedData = [];
        this.invoices.forEach((invoice, i) => {
          if (invoice.status.checked) {
            this.invoicesCheckedData.push(invoice);
            this.invoices[i + 1]["status"].active = true;
          }
        });
      }
      this.invoice.reset();
      this.resetNullValue();
      this.invoicesCheckedData.forEach((val) => {
        data = {
          invoice_id: val.invoice_short_uuid,
          amount: val.amount_remaining,
        };
        this.invoice.value.push(data);
      });
    }
    if (this.invoicesCheckedData.length == this.invoices.length) {
      this.isSelectedAll = true;
    }
    this.partialForm.get("partial_amount").setValue(null);
    this.calcTotalAmountOfAllInvoices(this.invoicesCheckedData);
    this.getfees(this.invoice.value);
  }

  calcTotalAmountOfAllInvoices(invoicesChecked) {
    this.subtotal = 0;
    this.total_discount = 0;
    this.total_invoice_amount = 0;
    this.total = 0;
    this.total_amount = 0;
    this.amountBeforeDiscount = 0;
    invoicesChecked.forEach((invoice) => {
      this.total_amount += Number(invoice.amount_remaining);
      this.total_invoice_amount = this.total_amount;
      this.total = Number(this.total_amount);
      this.total_discount += Number(invoice?.discount);
      this.CaluclateSubtotal(this.total_amount, 0);
      this.amountBeforeDiscount =
        Number(this.total) + Number(invoice?.discount);
    });
  }
  past_due(val) {
    var today = new Date();
    var date = new Date(val);
    if (date <= today) return true;
    return false;
  }
  methodChange(method: string) {
    this.payment_method = method;
  }

  submitFawry() {
    let amount = Number(this.calculateTotal(this.total, this.total_fees));
    this.helper.showSpinner();
    let uuids = this.invoice.value.map((el) => el.invoice_id);
    this.router.navigate([`pay/fawry`], {
      queryParams: {
        order_ids: uuids,
        amount: amount,
      },
    });
  }

  checkPolicy(event) {
    this.policyChecked = event.target.checked;
  }
  checkout() {
    let uuids = [];
    this.invoice.value.forEach((value) => {
      uuids.push(value.invoice_id);
    });
    if (Number(this.total_amount) < Number(this.total_discount)) {
      Swal.fire({
        title: this.tranlateService.instant(
          "The discount amount should be less the the totla amount"
        ),
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.tranlateService.instant("back"),
      }).then(() => {
        if (localStorage.getItem("user_type") == "2") {
          this.router.navigate(["/pages/dashboard/payer"]);
        } else if (localStorage.getItem("user_type")) {
          this.router.navigate(["/pages/dashboard/super"]);
        } else {
          this.router.navigate(["/auth/login"]);
        }
      });
    } else {
      if (this.payment_method === "credit") {
        window.location.href = this.partialForm.value.partial_amount
          ? `${environment.URL}${this.payment_integration}?order_ids=${uuids}
            &checkout_amount=${this.finalTotal}
            &partial_invoice=${this.id}&source=WEB, ${this.partialForm.value.partial_amount}`
          : `${environment.URL}${this.payment_integration}?order_ids=${uuids}
            &checkout_amount=${this.finalTotal}&source=WEB`;
      } else if (this.payment_method === "fawry") {
        this.submitFawry();
      } else if (this.payment_method === "installment") {
        this.router.navigate([
          `installment/contact/${uuids}/${this.finalTotal}`,
        ]);
        // if (
        //   this.install_value == "" ||
        //   this.install_value == null ||
        //   this.install_value == undefined
        // ) {
        //   Swal.fire({
        //     title: this.tranlateService.instant(
        //       "You must select installment period"
        //     ),
        //     icon: "warning",
        //     showCancelButton: false,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     confirmButtonText: this.tranlateService.instant("Continue"),
        //   });
        // } else {
        //   window.location.href =
        //     `${environment.URL}${this.payment_integration}/?currency=EGP&order_id=` +
        //     this.id +
        //     "&amount=" +
        //     this.payTotal +
        //     "&install=" +
        //     this.install_value;
        // }
      }
    }
  }
  click: boolean;
  select_installment(event, value: any) {
    this.click = !this.click;
    this.click ? (this.install_value = value) : (this.install_value = "");
  }
  calculateTotal(amount, fees) {
    if (fees === 0) {
      this.payTotal = Number(amount);
      return this.moneyFormatter(Number(amount));
    } else {
      this.payTotal = Number(amount) + Number(fees);
      return this.moneyFormatter(Number(amount) + Number(fees));
    }
  }

  // ################### Start Get All Invoices ##################
  getAllInvoices() {
    var today = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    let status = {};
    this.appService
      .GET(`report/unpaid/tenant`, { user_id: this.payer_id, date_to: today })
      .subscribe((res) => {
        this.allInvoices = res;
        this.allInvoices.sort((a, b) => {
          if (a.date_to > b.date_from) {
            return 1;
          }
          if (a.date_to < b.date_from) {
            return -1;
          }
          return 0;
        });
        for (let index = 0; index < this.allInvoices.length; index++) {
          status = {
            index: index,
            active: false,
            checked: false,
          };
          if (index === 0) {
            status["active"] = true;
          }
          this.allInvoices[index]["status"] = status;
        }
        this.helper.hideSpinner();
      });
  }

  showAllInvoices() {
    this.resetAllData();
    this.helper.showSpinner();
    this.showInvoices = !this.showInvoices;
    if (this.showInvoices) {
      this.partialChecked = false;
      this.total_amount = 0;
      this.getAllInvoices();
    } else {
      this.getURL();
      this.activeArray.forEach((ele) => {
        ele["index"] === 0 ? (ele["active"] = true) : (ele["active"] = false);
      });
      this.helper.hideSpinner();
    }
  }
  // ################### End Get All Invoices ##################
  resetAllData() {
    this.subtotal = 0;
    this.total_discount = 0;
    this.total_invoice_amount = 0;
    this.total = 0;
    this.total_fees = 0;
    this.total_amount = 0;
    this.amountBeforeDiscount = 0;
    this.finalTotal = 0;
    this.invoice.reset();
    this.partialCheckedShow = false;
    this.partialForm.get("partial_amount").setValue(null);
    this.invoicesCheckedData = [];
  }

  fawryType(event) {
    event.target.checked
      ? (this.fawry = event.target.value)
      : (this.fawry = "");
  }
  getInvoicesNumber(id) {
    this.helper.showSpinner();
    this.appService
      .GET(`report/count_unpaid_tenant`, { user_id: id })
      .subscribe((res) => {
        // if res == 0: user have 1 invoice, if res more than 0: user have multi invoices.
        // if have multi invoices: all invoices count == res + 1
        this.invoicesCount = res;
      });
  }
  CaluclateSubtotal(amount, discount, partial?) {
    partial
      ? (this.subtotal = +this.moneyFormatter(
          Number(partial) - Number(discount)
        ))
      : (this.subtotal = +this.moneyFormatter(
          Number(amount) - Number(discount)
        ));
  }
  // reset null values
  resetNullValue() {
    this.invoice.value.forEach((val) => {
      if (!val.invoice_id) {
        this.invoice.value.splice(val);
      }
    });
  }
}
