import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-disabled-payment',
  templateUrl: './disabled-payment.component.html',
  styleUrls: ['./disabled-payment.component.scss']
})
export class DisabledPaymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
