import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { StoreService } from '../../services/helpers/store.service';

@Directive({
  selector: '[appHasPage]'
})
export class HasPageDirective {
  user_role = localStorage.getItem('user_type')
  @Input() appHasPage: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: StoreService
  ) {}

  ngOnInit() {
    if(!['1', '2'].includes(this.user_role)){
        this.authService.currentRolePages.subscribe((userPermissions) => {
      // const hasAccess = userPermissions.includes(this.appPermissionBased),
      // admin_settings =
      const hasAccess = this.appHasPage.some(page => {
        return this.authService.checkForAccess(page);
      });
      if (hasAccess) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
    }else{
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
