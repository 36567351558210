<div class="d-flex justify-content-end">
  <span class="d-flex align-items-center nowrap"
    ><span class="num">{{ currentPage }}</span>
    {{ "to" | translate }}
    <ng-select
      [clearable]="false"
      [(ngModel)]="rowsNumber"
      (change)="changePage($event)"
      fullwidth
      class="select-role m-2"
      placeholder="{{ 'Select Page' | translate }}"
    >
      <ng-option [value]="5">{{ 5 }}</ng-option>
      <ng-option [value]="10">{{ 10 }}</ng-option>
      <ng-option [value]="20">{{ 20 }}</ng-option>
      <ng-option [value]="50">{{ 50 }}</ng-option>
      <ng-option [value]="100">{{ 100 }}</ng-option>
    </ng-select>
    {{ "Showing rows" | translate }}
    {{ "of" | translate }}
    <span class="num">{{ totalPages }}</span>
    <span class="num mx-3">
    </span>
  </span>
</div>
